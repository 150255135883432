export const loadFont = async (filename, base64) => {
  const arr = filename.split('.');
  const fontFamily = arr[0];
  const extension = arr[1].toLowerCase();

  const fontFace = new FontFace(
    fontFamily,
    `url(data:font/${extension};base64,${base64})`
  );

  try {
    await fontFace.load();
    document.fonts.add(fontFace);
    return fontFamily;
  } catch (error) {
    console.error(`Font ${fontFamily} failed to load`, error);
    return null;
  }
};

export const addFontFace = async (name, base64String) => {
  console.log('addFontFace', name, base64String.length);

  // document.head.insertAdjacentHTML(
  //   'beforeend',
  //   `<style>
  //     @font-face {
  //         font-family: 'myfont';
  //         src: url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap)format('woff2');
  //         font-weight: normal;
  //         font-style: normal;
  //     }
  //   </style>`
  // );
  // document.querySelector('p').style['fontFamily'] = 'myfont';
};
