import React, { useEffect, useState } from 'react';

import styles from './RippleButton.module.scss';

const RippleButton = ({ children, onClick, className, ...props }) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);

      setTimeout(() => setIsRippling(false), 700);
      return;
    }

    setIsRippling(false);
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  const handleClick = (e) => {
    onClick && onClick(e);

    const rect = e.target.getBoundingClientRect();
    if (!isRippling)
      setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  return (
    <button
      {...props}
      className={styles.rippleButton + ' ' + className}
      onClick={handleClick}
    >
      {isRippling ? (
        <span
          className={styles.ripple}
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      ) : (
        ''
      )}
      <span className={styles.content}>{children}</span>
    </button>
  );
};

export default RippleButton;
