export function getFilenameWithoutExtension(filename) {
  if (!filename) {
    console.warn('No file name provided to getFilenameWithoutExtension()');
    return null;
  }

  if (filename.includes('.') === true) {
    return filename.split('.').slice(0, -1).join('.');
  }

  return filename;
}

export function getFileExtension(fileNameOrExtension) {
  if (!fileNameOrExtension) {
    console.warn('No file name or extension provided to getFileExtension()');
    return null;
  }

  if (fileNameOrExtension.includes('.') === true) {
    return fileNameOrExtension.split('.').pop();
  }

  return null;
}

export const isDataUrl = (dataUrl) => {
  return dataUrl.startsWith('data:') && dataUrl.includes('base64');
};

export const getBase64FromDataUrl = (dataUrl) => {
  const arr = dataUrl.split(',');

  if (arr.length !== 2) {
    console.error('getBase64FromDataUrl(): Invalid dataUrl');
    return null;
  }

  return arr[1];
};

export const getDataUrlMimeType = (dataUrl) => {
  const mime = dataUrl.split(';')[0].split(':')[1];
  // console.log('mime', mime);
  return mime;
};

export const base64ToBlob = async (dataUrlOrBase64, contentType) => {
  let base64 = dataUrlOrBase64;

  if (isDataUrl(dataUrlOrBase64) === true) {
    base64 = getBase64FromDataUrl(dataUrlOrBase64);
    contentType = getDataUrlMimeType(dataUrlOrBase64);
  }

  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const downloadBase64 = async (base64, filename, contentType) => {
  const blob = await base64ToBlob(base64, contentType);
  const url = URL.createObjectURL(blob);

  downloadFile(url, filename);

  // Clean up the URL object after usage
  setTimeout(() => URL.revokeObjectURL(url), 100);
};

export const downloadFile = (url, filename) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getFileUploadObject = (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = (error) => {
      reader.abort();
      reject(error);
    };

    reader.onload = function () {
      let bytes = Array.from(new Uint8Array(this.result));

      let base64 = btoa(
        bytes.map((item) => String.fromCharCode(item)).join('')
      );

      resolve({
        bytes,
        bytesLength: bytes.length,
        base64,
        base64Length: base64.length,
        name: file.name,
        type: file.type,
        extension: getFileExtension(file.name),
      });
    };

    reader.readAsArrayBuffer(file);
  });
};
