import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const AddButton = ({ onClick, className }) => {
  return (
    <button
      className={`btn btn-success rounded-pill ${className ? className : ''}`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
    </button>
  );
};

export default AddButton;
