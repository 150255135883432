import Resizer from 'react-image-file-resizer';
import { getFileExtension } from './files.utils';

export const resizeImage = (filename, blob, max) => {
  let extension = getFileExtension(filename).toUpperCase();
  if (extension === 'JPG') extension = 'JPEG';

  // console.log('Resizing image:', extension);

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      blob,
      max, // maxWidth
      max, // maxHeight
      extension, // compressFormat (JPEG, PNG, WEBP)
      100, // quality
      0, // rotation (0, 90, 180, 270, 360)
      (uri) => {
        resolve(uri);
      },
      'base64' // outputType (base64, blob, file)
    );
  });
};

export const svgToPng = (svgBase64) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      resolve(canvas.toDataURL('image/png'));
    };

    img.src = svgBase64;
  });
};

export const changeFavicon = (base64) => {
  // console.log(`  Changing favicon`);

  [...document.querySelectorAll("head link[rel*='icon']")].forEach(
    (favicon) => {
      favicon.remove();
    }
  );

  if (!base64) return;

  const link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = `data:@file/png;base64,${base64}`;
  document.querySelector('head').appendChild(link);
};
