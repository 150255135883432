import React, { useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import './style.scss';

const EditableContent = ({
  value,
  onChange,
  allowHtml = false,
  allowEmptyText = false,
  disabled = false,
  className,
}) => {
  const ref = useRef(null);
  const [content, setContent] = useState(value);

  useEffect(() => {
    // Disable spellcheck
    ref.current.el.current.spellcheck = false;
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current.el.current.blur();
    }
  };

  const handleChange = (e) => {
    let text = e.target.value;

    allowHtml
      ? (text = text.trim())
      : (text = text.replace(/<\/?[^>]+(>|$)/g, '').trim());

    setContent(text);
    onChange(text);
  };

  const handleBlur = (e) => {
    let text = e.target.innerHTML;
    text = text.replace(/&nbsp;/g, '').trim();

    if (allowEmptyText === false && text.length === 0) {
      setContent('Inget innehåll');
      onChange('Inget innehåll');
    }
  };

  return (
    <ContentEditable
      className={className}
      html={content}
      disabled={disabled}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onBlur={handleBlur}
      ref={ref}
    />
  );
};

export default EditableContent;
