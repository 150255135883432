import React from 'react';

const HtmlDiv = ({ children, ...props }) => {
  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
};

export default HtmlDiv;
