import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonAnswerText = ({ children, hasAnswered }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      {hasAnswered && <FontAwesomeIcon icon={faCheck} className="me-2" />}{' '}
      <span>{children}</span>
    </div>
  );
};

export default ButtonAnswerText;
