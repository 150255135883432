const { signal } = require('@preact/signals-react');

export const signalCourses = signal([]);
export const signalLessons = signal([]);
export const signalQuestions = signal([]);
export const signalSelectedCourseId = signal(null);
export const signalSelectedLessonId = signal(null);
export const signalSelectedQuestionId = signal(null);
export const signalEditedCourse = signal(null);
export const signalEditedLesson = signal(null);
export const signalEditedQuestion = signal(null);
