import { FRAGETYP, ATTACHMENT_TYPE } from '../constants';

export const courseTemplate = {
  id: 'new',
  name: 'COURSE NAME',
  description: 'Kursbeskrivning',
  lessons: [],
  isActive: true,
};

export const lessonTemplate = {
  name: 'LESSON NAME',
  description: 'Lektionsbeskrivning',
  questions: [],
  isActive: true,
};

export const questionTemplate = {
  id: 'new',
  name: 'QUESTION NAME',
  description: 'DESCRIPTION',
  attachmentName: null,
  attachmentId: null,
  attachmentType: ATTACHMENT_TYPE.IMAGE,
  attachmentContent: null,
  categories: [],
  answers: [],
  possibleAnswers: [],
  correctAnswer: [],
  correctAnswerMessage: '',
  wrongAnswerMessage: '',
  answerMessage: '',
  isActive: true,
  version: 1,
  type: FRAGETYP.INFORMATION,
  metaData: null,
};
